<template>
	<div>
		<Preloader ref="preloader"></Preloader>

		<HeaderMain></HeaderMain>

		<MainSection></MainSection>
		<AboutUsSection></AboutUsSection>
		<ModpacksSection></ModpacksSection>
		<ShopSection></ShopSection>

		<Footer></Footer>
	</div>
</template>

<script>
	import HeaderMain from "../components/HeaderMain";
	import Preloader from "../components/Preloader";
	import MainSection from "../components/MainSection";
	import AboutUsSection from "../components/AboutUsSection";
	import ModpacksSection from "../components/ModpacksSection";
	import Footer from "../components/Footer";
	import ShopSection from "../components/ShopSection";

	export default {
		name: "MainPage",
		components: {ShopSection, Footer, ModpacksSection, AboutUsSection, MainSection, Preloader, HeaderMain},
		mounted() {
			this.$refs.preloader.$el.style.display = 'none';
		}
	}
</script>
