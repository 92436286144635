<template>
    <div class="Dropdown" ref="dropdown" :name="name">
        <button class="Dropdown__header">
            <span class="Dropdown__title">{{title}}</span>
            <span class="Dropdown__caret">&#10095;</span>
        </button>

		<ul class="Dropdown__items">
        	<slot name="list"></slot>
		</ul>
    </div>
</template>

<script>
import $ from 'jquery';

export default {
    name: 'Dropdown',
    props: {
        name: {
            type: String
        },
        title: {
            type: String
        },
        default: {
            type: String
        }
    },
    mounted() {
        let dropdown = this.$refs.dropdown;

        if (this.default)
            $(dropdown).find(`.DropdownItem[data-value="${this.default}"]`);

        let currentItem = dropdown.getElementsByClassName('Dropdown__current')[0];
        if (currentItem)
            dropdown.getElementsByClassName('Dropdown__title')[0].innerHtml = currentItem.innerHTML;

        dropdown.addEventListener('change', (e) => {
            $(dropdown).toggleClass('open');
            this.$emit('change', e);
        });

        $(dropdown).find('.Dropdown__header').click(e => {
            e.dropdown = dropdown;
            $(dropdown).toggleClass('open');
        });

        $(window).click(e => {
            if (e.dropdown === dropdown)
                $(dropdown).removeClass('open');
        });
    }
}
</script>

<style scoped>
	.Dropdown {
		position: relative;
		color: #fff;
		width: 20em;
		user-select: none;
		cursor: pointer;
	}

	.Dropdown * {
		outline: none;
	}

	.Dropdown__header {
		width: 100%;
		border: solid 0.15em #6e42ff;
		border-radius: 2.25em;
		background: rgba(0, 0, 0, .4);
		padding: 0.9em 1.2em;
		vertical-align: middle;
		text-align: left;
		position: relative;
		cursor: pointer;
	}

	.Dropdown__header::before,
	.Dropdown__header::after {
		content: "";
		width: 70%;
		height: 0.15em;
		position: absolute;
		left: 50%;
		transform: translateX(-50%);
		background: linear-gradient(to right, #6e42ff 0%, #72e8ff 40%, #72e8ff 60%, #6e42ff 100%);
	}

	.Dropdown__header::before {
		top: -0.15em;
	}

	.Dropdown__header::after {
		bottom: -0.15em;
	}

	.Dropdown__header:focus {
		background: rgba(0, 0, 0, .4);
	}

	.Dropdown__title {
		float: left;
		padding-right: 0.75em;
		color: #fff;
    max-width: 90%;
		font-size: 1.2em;
		white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
	}

	.Dropdown__caret {
		color: #6e42ff;
		font-size: 1.2em;
		-webkit-transform: rotate(90deg);
		-ms-transform: rotate(90deg);
		transform: rotate(90deg);
		float: right;
	}

	.Dropdown__items {
		display: none;
		list-style: none;
		margin: 0.75em 0 0;
		text-align: left;
		background: rgba(0, 0, 0, .9);
		border-radius: 0.3em;
		box-shadow: 0 0.45em 0.9em rgba(0, 0, 0, .175);
		max-height: 18.75em;
		overflow-x: hidden;
		overflow-y: auto;
		z-index: 2;
		position: absolute;
		left: 0;
		opacity: 0;
		transition: all 3s;
	}

	.Dropdown.open .Dropdown__items {
		display: block;
		opacity: 1;
	}
</style>
