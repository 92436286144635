<template>
    <li class="DropdownItem" :data-value="dataValue" @click="onClick"><slot></slot></li>
</template>

<script>
export default {
    name: 'DropdownItem',
    props: {
        dataValue: {
            type: String
        }
    },
    methods: {
        onClick(e) {
            let dropdown = $(e.target).closest('.Dropdown')[0];
            dropdown.getElementsByClassName('Dropdown__title')[0].innerHTML = e.target.innerHTML;
            dropdown.value = e.target.dataset.value;

            let prevCurrent = dropdown.getElementsByClassName('Dropdown__current')[0];
            if (prevCurrent)
                prevCurrent.classList.remove('Dropdown__current');

            e.target.classList.add('Dropdown__current');

            if (e.target !== prevCurrent)
                dropdown.dispatchEvent(new Event('change'));
        }
    }
}
</script>

<style>
.DropdownItem {
    padding: 12px 16px;
    white-space: nowrap;
    cursor: pointer;
    font-weight: 500;
    font-size: 16px;
}

.DropdownItem:focus,
.DropdownItem:hover {
    background: #6e42ff;
}

.Dropdown__current {
    background: #6f2cbf;
}
</style>
