<template>
	<div class="modal" ref="modal" style="display: none">
		<div class="modal__box">
			<div class="modal__close" @click="closeCart">
				<em class="fa fa-times"></em>
			</div>
			<div class="modal__container">
				<h3 class="modal__title">{{ $t('cart.title') }}</h3>
				<div class="modal__content">
					<table cellspacing="0">
						<thead>
							<tr>
								<th>{{ $t('cart.name') }}</th>
								<th>{{ $t('cart.modpack') }}</th>
								<th>{{ $t('cart.count') }}</th>
								<th>{{ $t('cart.price') }}</th>
								<th></th>
							</tr>
						</thead>
						<tbody>
							<tr v-for="item in items()" v-if="item" :key="item.id">
								<td>{{item.title}}</td>
								<td>{{modpack(item.modpack).title}}</td>
								<td>{{item.count}}</td>
								<td>{{(item.count * (item.cost - (item.cost / 100 * item.sale))).toFixed(2)}} €</td>
								<td class="table__buttons">
									<div>
										<div class="button add" @click="addToCart(item.id)">+</div>
										<div class="button remove" @click="removeFromCart(item.id)">-</div>
									</div>
								</td>
							</tr>
							<tr>
								<td>{{ $t('cart.total') }}</td>
								<td></td>
								<td></td>
								<td>{{items().reduce((accumulator, item) => (accumulator + (item.cost - (item.cost / 100 * item.sale)) * item.count), 0).toFixed(2)}} €</td>
								<td></td>
							</tr>
						</tbody>
					</table>
					<div class="modal__buttons">
						<div class="form__input">
							<input type="text" :placeholder="$t('cart.login')" :value="login" @input="e => login = e.target.value">
						</div>
						<div class="button buy" @click="buy">{{ $t('cart.buy') }}</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import { mapGetters } from 'vuex';

	export default {
		name: "Cart",
		computed: {
			...mapGetters([
				'modpack/list',
				'cart/list',
			]),

			cart() {
				return this['cart/list'];
			},

			cartItemsCount() {
				return this.cart.length;
			}
		},
		data: () => ({
			shopItems: {},
			login: ''
		}),
		methods: {
			items() {
				return Object.values(this.shopItems);
			},

			modpack(modpackId) {
				return this['modpack/list'][modpackId];
			},

			async buy() {
				if (this.cartItemsCount > 0)
					location.href = `/#/cart?login=${this.login}&items=${encodeURIComponent(JSON.stringify(this.cart))}`;
			},

			async updateCartData() {
				this.shopItems = {};

				for (let itemId of this.cart) {
					let item = this.shopItems[itemId];
					if (!item) {
						item = await window.ShopManager.findOneById(itemId);
						this.shopItems[item.id] = item;
						item.count = 0;
					}

					item.count++;
				}

				this.$forceUpdate();
			},

			addToCart(itemId) {
				this.$store.dispatch('cart/addToCart', itemId);
				this.updateCartData();
			},

			removeFromCart(itemId) {
				this.$store.dispatch('cart/removeFromCart', itemId);
				this.updateCartData();
			},

			async openCart() {
				this.login = localStorage.getItem('login') || '';

				await this.updateCartData();

				$(this.$refs.modal).fadeIn(200);
			},

			closeCart() {
				$(this.$refs.modal).fadeOut(200);
			},
		},
		watch: {
			login(value) {
				localStorage.setItem('login', value);
			}
		}
	}
</script>

<style scoped>

</style>
