<template>
  <section id="shop">
    <div class="shop-settings">
      <div class="shop-setting">
        <h2>{{ $t('shop.modpack') }}</h2>
        <dropdown :title="$t('shop.select-modpack')" @change="modpackChange">
          <template slot="list">
            <dropdown-item
              v-for="modpack in modpacks"
              :key="modpack.id"
              :data-value="modpack.id"
              >{{ modpack.title }}</dropdown-item
            >
          </template>
        </dropdown>
      </div>

      <div class="shop-setting">
        <h2>{{ $t('shop.category') }}</h2>
        <dropdown :title="$t('shop.select-category')" @change="categoryChange">
          <template slot="list">
            <dropdown-item
              v-for="category in categories"
              :key="category.id"
              :data-value="category.id"
              >{{ category.title }}</dropdown-item
            >
          </template>
        </dropdown>
      </div>
    </div>

    <ul class="shop-items">
      <li class="shop-item" v-for="item in shopItems">
        <template v-if="item.type === 0">
          <div class="item-image">
            <img
              :src="
                'https://static.kaboom2.net/images/' +
                  getItemImage(item) +
                  '.png'
              "
              draggable="false"
            />
          </div>
          <div
            class="item-title"
            :style="{
              background: `linear-gradient(to right, ${item.color} 0%, rgba(0, 0, 0, 0) 100%)`,
            }"
          >
            {{ item.title }}
          </div>
          <ul class="item-info">
            <li>
              <span class="info-key">{{ $t('shop.role.warps') }}:</span>
              <span class="info-value green">{{ item.warps }}</span>
            </li>
            <li>
              <span class="info-key">{{ $t('shop.role.private-warps') }}</span>
              <span class="info-value green">{{ item.privateWarps }}</span>
            </li>
            <li>
              <span class="info-key">{{ $t('shop.role.regions') }}:</span>
              <span class="info-value green">{{ item.regions }}</span>
            </li>
            <li>
              <span class="info-key">{{ $t('shop.role.join-full-server') }}:</span>
              <span
                :class="[
                  'info-value',
                  { green: item.joinFullServer, red: !item.joinFullServer },
                ]"
                >{{ item.joinFullServer ? $t('shop.yes') : $t('shop.no') }}</span
              >
            </li>
            <li>
              <span class="info-key">{{ $t('shop.role.keep-inventory') }}:</span>
              <span
                :class="[
                  'info-value',
                  { green: item.keepInventory, red: !item.keepInventory },
                ]"
                >{{ item.keepInventory ? $t('shop.yes') : $t('shop.no') }}</span
              >
            </li>
            <li>
              <span class="info-key">{{ $t('shop.role.keep-experience') }}:</span>
              <span
                :class="[
                  'info-value',
                  { green: item.keepExperience, red: !item.keepExperience },
                ]"
                >{{ item.keepExperience ? $t('shop.yes') : $t('shop.no') }}</span
              >
            </li>
            <li>
              <span class="info-key">{{ $t('shop.role.anti-afk') }}:</span>
              <span
                :class="[
                  'info-value',
                  { green: item.antiAfk, red: !item.antiAfk },
                ]"
                >{{ item.antiAfk ? $t('shop.yes') : $t('shop.no') }}</span
              >
            </li>
            <li>
              <span class="info-key">{{ $t('shop.role.additionals') }}:</span>
              <span
                class="info-value green fa fa-info-circle info-other"
                @click="more(item)"
              ></span>
            </li>
          </ul>
        </template>

        <template v-else-if="item.type === 1">
          <div class="item-image">
            <img
              :src="'https://static.kaboom2.net/images/cases/case.png'"
              draggable="false"
            />
          </div>
          <div
            class="item-title"
            :style="{
              background: `linear-gradient(to right, ${item.color} 0%, rgba(0, 0, 0, 0) 100%)`,
            }"
          >
            {{ item.title }}
          </div>
          <p style="text-align: center; margin-bottom: 1rem">
            {{ item.description }}
          </p>
          <img
            class="full-width"
            :src="
              'https://static.kaboom2.net/images/' + getItemImage(item) + '.png'
            "
            draggable="false"
          />
        </template>

        <template v-else-if="item.type === 2">
          <div
            class="item-title"
            :style="{
              background: `linear-gradient(to right, ${item.color} 0%, rgba(0, 0, 0, 0) 100%)`,
            }"
          >
            {{ item.title }}
          </div>
          <img
            :src="
              'https://static.kaboom2.net/images/' + getItemImage(item) + '.png'
            "
            draggable="false"
          />
        </template>

        <div class="item-price">
          {{ $t('shop.price') }}: {{ (item.cost - (item.cost / 100) * item.sale).toFixed(2) }}
          <em class="fa fa-euro-sign"></em>
          <span class="item-sale" v-if="item.sale !== 0"
            >{{ item.cost.toFixed(2) }} <em class="fa fa-euro-sign"></em
          ></span>
        </div>
        <div class="item-button" @click="addToCart(item.id)">
          <em class="fa fa-shopping-cart"></em>{{ $t('shop.add-to-cart') }}
        </div>
        <div class="badge-overlay" v-if="item.sale !== 0">
          <span class="top-right badge">{{ item.sale * -1 }}%</span>
        </div>
      </li>
    </ul>
  </section>
</template>

<script>
import { mapGetters } from "vuex";
import Dropdown from "./Dropdown";
import DropdownItem from "./DropdownItem";

export default {
  name: "ShopSection",
  components: { DropdownItem, Dropdown },
  computed: {
    ...mapGetters(["modpack/list", "category/list", "shop/list"]),

    modpacks() {
      return Object.values(this["modpack/list"]).sort((a, b) =>
        a.title > b.title ? 1 : -1
      );
    },

    categories() {
      return Object.values(this["category/list"]).sort((a, b) =>
        a.id > b.id ? 1 : -1
      );
    },

    shopItems() {
      return Object.values(this["shop/list"]).sort((a, b) =>
        a.id > b.id ? 1 : -1
      );
    },
  },
  data() {
    return {
      currentModpack: null,
      currentCategory: null,
      error: null,
    };
  },
  methods: {
    getShopItems() {
      if (this.currentCategory && this.currentModpack) {
        window.ShopManager._loadShopItemList(
          this.currentCategory,
          this.currentModpack
        );
      }
    },

    getItemCategory(item) {
      return this["category/list"][item.category];
    },

    getItemImage(item) {
      let category = this.getItemCategory(item);

      switch (category.name) {
        case "groups":
          return "heads/" + item.name;
        case "items":
          return "items/" + item.name;
        case "cases":
          return "cases/" + item.name;
      }
    },

    modpackChange(e) {
      this.currentModpack = e.target.value;
      this.getShopItems();
    },
    categoryChange(e) {
      this.currentCategory = e.target.value;
      this.getShopItems();
    },

    more(item) {
      let modalContent = "";
      modalContent += '<h3 class="modal__title">' + item.title + "</h3>";
      modalContent += '<div class="modal__content">';

      if (item.other) {
        modalContent += '<ul class="mod-list">';
        item.other?.forEach((line) => {
          modalContent += "<li>" + line + "</li>";
        });
        modalContent += "</ul>";
      }

      modalContent += "</div>";

      app.showModal(modalContent);
    },

    addToCart(itemId) {
      this.$store.dispatch("cart/addToCart", itemId);
    },
  },
};
</script>

<style scoped>
#shop {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.shop-settings {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin-bottom: 2em;
}

.shop-settings h2 {
  font-size: 1.92rem;
  color: #fff;
  text-transform: uppercase;
  margin-bottom: 1rem;
  text-align: center;
}

.shop-items {
  list-style: none;
  width: 80vw;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
}

.shop-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 0.3rem;
  margin-right: 1rem;
  margin-bottom: 2rem;
  background: linear-gradient(to right, #29093b 0%, #1f0833 100%);
  text-transform: uppercase;
  color: #fff;
  text-shadow: 0 0.06rem #000;
  position: relative;
  width: 15rem;
  border-radius: 1.5rem 1.5rem 1.5rem 1.5rem;
}

.shop-item .item-image {
  width: 6rem;
  height: 6rem;
  position: absolute;
  top: -0.9rem;
  left: -1.2rem;
}

.shop-item .item-image img {
  width: 5.4rem !important;
  height: 5.4rem !important;
  image-rendering: pixelated;
}

.shop-item .item-title {
  width: 100%;
  height: 2.6rem;
  padding: 0.8rem 0;
  text-align: center;
  font-size: 0.9rem;
  font-weight: bold;
  border-radius: 1.5rem 1.5rem 0 0;
  margin-bottom: 1.2rem;
}

.shop-item .item-image + .item-title {
  padding-left: 4rem;
  text-align: left;
}

.shop-item .item-info {
  list-style: none;
  width: 100%;
}

.shop-item .item-info li {
  display: flex;
  justify-content: space-between;
  font-size: 0.6rem;
  padding: 0.3rem 0.6rem 0.1rem 0.6rem;
}

.shop-item .item-info li span:first-child {
  margin-right: 0.5rem;
}

.shop-item > img {
  width: 128px;
  height: 128px;
  image-rendering: pixelated;
}

.shop-item > img.full-width {
  width: 100%;
  height: auto;
}

.shop-item .item-price {
  font-size: 0.96rem;
  width: 100%;
  text-align: center;
  padding-top: 1rem;
  position: relative;
}

.shop-item .item-sale {
  position: absolute;
  bottom: 1em;
  font-size: 0.8em;
  font-weight: bolder;
  color: #d52921;
  text-decoration: line-through;
  transform: translateX(0.6em);
}

.shop-item .item-button {
  width: 100%;
  text-align: center;
  font-size: 0.96rem;
  padding: 0.5rem;
  margin-top: 0.6rem;
  background: linear-gradient(to top, #98c514 0%, #b9cb23 100%);
  cursor: pointer;
  border-radius: 1.5rem;
  color: #000;
}

.shop-item .item-button em {
  margin-right: 0.5rem;
}

.info-other:hover {
  cursor: pointer;
}

.badge-overlay {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  pointer-events: none;
  z-index: 1;
  -webkit-transition: width 1s ease, height 1s ease;
  -moz-transition: width 1s ease, height 1s ease;
  -o-transition: width 1s ease, height 1s ease;
  transition: width 0.4s ease, height 0.4s ease;
}

.badge {
  margin: 0;
  color: white;
  padding: 0.8em 10px;
  font-size: 1em;
  font-family: Arial, Helvetica, sans-serif;
  text-align: center;
  line-height: normal;
  text-transform: uppercase;
  background: #ed1b24;
}

.badge::before,
.badge::after {
  content: "";
  position: absolute;
  top: 0;
  margin: 0 -1px;
  width: 100%;
  height: 100%;
  background: inherit;
  min-width: 55px;
}

.badge::before {
  right: 100%;
}

.badge::after {
  left: 100%;
}

.top-right {
  position: absolute;
  top: 0;
  right: 0;
  transform: translateX(30%) translateY(0%) rotate(45deg);
  transform-origin: top left;
}
</style>
