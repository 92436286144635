var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{attrs:{"id":"shop"}},[_c('div',{staticClass:"shop-settings"},[_c('div',{staticClass:"shop-setting"},[_c('h2',[_vm._v(_vm._s(_vm.$t('shop.modpack')))]),_c('dropdown',{attrs:{"title":_vm.$t('shop.select-modpack')},on:{"change":_vm.modpackChange}},[_c('template',{slot:"list"},_vm._l((_vm.modpacks),function(modpack){return _c('dropdown-item',{key:modpack.id,attrs:{"data-value":modpack.id}},[_vm._v(_vm._s(modpack.title))])}),1)],2)],1),_c('div',{staticClass:"shop-setting"},[_c('h2',[_vm._v(_vm._s(_vm.$t('shop.category')))]),_c('dropdown',{attrs:{"title":_vm.$t('shop.select-category')},on:{"change":_vm.categoryChange}},[_c('template',{slot:"list"},_vm._l((_vm.categories),function(category){return _c('dropdown-item',{key:category.id,attrs:{"data-value":category.id}},[_vm._v(_vm._s(category.title))])}),1)],2)],1)]),_c('ul',{staticClass:"shop-items"},_vm._l((_vm.shopItems),function(item){return _c('li',{staticClass:"shop-item"},[(item.type === 0)?[_c('div',{staticClass:"item-image"},[_c('img',{attrs:{"src":'https://static.kaboom2.net/images/' +
                _vm.getItemImage(item) +
                '.png',"draggable":"false"}})]),_c('div',{staticClass:"item-title",style:({
            background: ("linear-gradient(to right, " + (item.color) + " 0%, rgba(0, 0, 0, 0) 100%)"),
          })},[_vm._v(" "+_vm._s(item.title)+" ")]),_c('ul',{staticClass:"item-info"},[_c('li',[_c('span',{staticClass:"info-key"},[_vm._v(_vm._s(_vm.$t('shop.role.warps'))+":")]),_c('span',{staticClass:"info-value green"},[_vm._v(_vm._s(item.warps))])]),_c('li',[_c('span',{staticClass:"info-key"},[_vm._v(_vm._s(_vm.$t('shop.role.private-warps')))]),_c('span',{staticClass:"info-value green"},[_vm._v(_vm._s(item.privateWarps))])]),_c('li',[_c('span',{staticClass:"info-key"},[_vm._v(_vm._s(_vm.$t('shop.role.regions'))+":")]),_c('span',{staticClass:"info-value green"},[_vm._v(_vm._s(item.regions))])]),_c('li',[_c('span',{staticClass:"info-key"},[_vm._v(_vm._s(_vm.$t('shop.role.join-full-server'))+":")]),_c('span',{class:[
                'info-value',
                { green: item.joinFullServer, red: !item.joinFullServer } ]},[_vm._v(_vm._s(item.joinFullServer ? _vm.$t('shop.yes') : _vm.$t('shop.no')))])]),_c('li',[_c('span',{staticClass:"info-key"},[_vm._v(_vm._s(_vm.$t('shop.role.keep-inventory'))+":")]),_c('span',{class:[
                'info-value',
                { green: item.keepInventory, red: !item.keepInventory } ]},[_vm._v(_vm._s(item.keepInventory ? _vm.$t('shop.yes') : _vm.$t('shop.no')))])]),_c('li',[_c('span',{staticClass:"info-key"},[_vm._v(_vm._s(_vm.$t('shop.role.keep-experience'))+":")]),_c('span',{class:[
                'info-value',
                { green: item.keepExperience, red: !item.keepExperience } ]},[_vm._v(_vm._s(item.keepExperience ? _vm.$t('shop.yes') : _vm.$t('shop.no')))])]),_c('li',[_c('span',{staticClass:"info-key"},[_vm._v(_vm._s(_vm.$t('shop.role.anti-afk'))+":")]),_c('span',{class:[
                'info-value',
                { green: item.antiAfk, red: !item.antiAfk } ]},[_vm._v(_vm._s(item.antiAfk ? _vm.$t('shop.yes') : _vm.$t('shop.no')))])]),_c('li',[_c('span',{staticClass:"info-key"},[_vm._v(_vm._s(_vm.$t('shop.role.additionals'))+":")]),_c('span',{staticClass:"info-value green fa fa-info-circle info-other",on:{"click":function($event){return _vm.more(item)}}})])])]:(item.type === 1)?[_c('div',{staticClass:"item-image"},[_c('img',{attrs:{"src":'https://static.kaboom2.net/images/cases/case.png',"draggable":"false"}})]),_c('div',{staticClass:"item-title",style:({
            background: ("linear-gradient(to right, " + (item.color) + " 0%, rgba(0, 0, 0, 0) 100%)"),
          })},[_vm._v(" "+_vm._s(item.title)+" ")]),_c('p',{staticStyle:{"text-align":"center","margin-bottom":"1rem"}},[_vm._v(" "+_vm._s(item.description)+" ")]),_c('img',{staticClass:"full-width",attrs:{"src":'https://static.kaboom2.net/images/' + _vm.getItemImage(item) + '.png',"draggable":"false"}})]:(item.type === 2)?[_c('div',{staticClass:"item-title",style:({
            background: ("linear-gradient(to right, " + (item.color) + " 0%, rgba(0, 0, 0, 0) 100%)"),
          })},[_vm._v(" "+_vm._s(item.title)+" ")]),_c('img',{attrs:{"src":'https://static.kaboom2.net/images/' + _vm.getItemImage(item) + '.png',"draggable":"false"}})]:_vm._e(),_c('div',{staticClass:"item-price"},[_vm._v(" "+_vm._s(_vm.$t('shop.price'))+": "+_vm._s((item.cost - (item.cost / 100) * item.sale).toFixed(2))+" "),_c('em',{staticClass:"fa fa-euro-sign"}),(item.sale !== 0)?_c('span',{staticClass:"item-sale"},[_vm._v(_vm._s(item.cost.toFixed(2))+" "),_c('em',{staticClass:"fa fa-euro-sign"})]):_vm._e()]),_c('div',{staticClass:"item-button",on:{"click":function($event){return _vm.addToCart(item.id)}}},[_c('em',{staticClass:"fa fa-shopping-cart"}),_vm._v(_vm._s(_vm.$t('shop.add-to-cart'))+" ")]),(item.sale !== 0)?_c('div',{staticClass:"badge-overlay"},[_c('span',{staticClass:"top-right badge"},[_vm._v(_vm._s(item.sale * -1)+"%")])]):_vm._e()],2)}),0)])}
var staticRenderFns = []

export { render, staticRenderFns }