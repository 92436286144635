<template>
	<header>
		<label class="menu-bar" for="bar"><i class="fa fa-bars"></i></label><input id="bar" type="checkbox">
		<ul>
			<li data-section="#main">{{ $t('header.main') }}</li>
			<li data-section="#about-us">{{ $t('header.about-us') }}</li>
			<li data-section="#modpacks">{{ $t('header.servers') }}</li>
			<li data-section="#shop">{{ $t('header.shop') }}</li>
			<li><a href="https://forum.kaboom2.net/" target="_blank">{{ $t('header.forum') }}</a></li>
		</ul>
		<div class="cart" @click="$refs.cart && $refs.cart.openCart()"><em class="fa fa-shopping-cart"></em><div class="counter" v-if="cartItemsCount > 0">{{cartItemsCount < 100 ? cartItemsCount : '99+'}}</div></div>

		<Cart ref="cart"></Cart>
	</header>
</template>

<script>
	import { mapGetters } from 'vuex';
	import Cart from "./Cart";

	export default {
		name: "HeaderMain",
		components: {Cart},
		computed: {
			...mapGetters([
				'cart/list',
			]),

			cart() {
				return this['cart/list'];
			},

			cartItemsCount() {
				return this.cart.length;
			}
		},
		mounted() {
			$('[data-section]').on('click', function() {
				$('html, body').animate({
					scrollTop: $('body').scrollTop() + $($(this).data('section')).offset().top - window.innerHeight / 2 + $($(this).data('section')).height() / 2
				}, 1300);
			});
		}
	}
</script>

<style scoped>
	header {
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 3rem;
		color: #8a9199;
		z-index: 10;
		transition: background .5s;
		background: linear-gradient(to bottom, rgba(45, 28, 51, 0) 0%, rgba(27, 19, 30, 0) 100%);
		display: flex;
		align-items: center;
	}

	header > input[type="checkbox"] {
		display: none;
	}

	header .menu-bar {
		width: 2rem;
		height: 2rem;
		font-size: 2rem;
		margin-left: 1.2rem;
		display: none;
		justify-content: center;
		align-items: center;
		cursor: pointer;
	}

	header .menu-bar:hover {
		color: #fff;
	}

	header ul {
		list-style: none;
		height: 100%;
		display: flex;
	}

	header li {
		height: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
		padding: 0 1.2rem;
		white-space: nowrap;
	}

	header li:hover {
		color: #fff;
		cursor: pointer;
	}

	header li.menu_logo {
		font-size: 1.4rem;
		color: #fff;
		cursor: default;
	}

	header .cart {
		background: #97c514 linear-gradient(to top, #97c514 0%, #d2d92a 100%);
		width: 4rem;
		height: 4rem;
		border-radius: 50%;
		position: absolute;
		top: 1rem;
		right: 1rem;
		display: flex;
		justify-content: center;
		align-items: center;
		cursor: pointer;
	}

	header .cart::before {
		content: "";
		width: 1em;
		height: 1em;
		background: #97c514 linear-gradient(to top, #97c514 0%, #d2d92a 100%);
		border-radius: 50%;
		position: absolute;
		top: 50%;
		left: -2rem;
		transform: translateY(-50%);
	}

	header .cart em {
		font-size: 1.6rem;
		color: #fff;
		opacity: .7;
	}

	header .cart .counter {
		position: absolute;
		top: 0;
		left: 80%;
		transform: translateX(-50%);
		height: 1.2rem;
		justify-content: center;
		align-items: center;
		background: #9e0000;
		color: #fff;
		font-size: .9rem;
		padding: 0 .4rem;
		border-radius: 2rem;
	}
</style>
