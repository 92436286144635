<template>
	<section id="about-us">
		<!-- <p class="info">
			<br/><br/><br/><br/><br/>Играйте у нас.<br/><br/>Счастья вам, здоровья, квартиру, дачу, кексов в придачу!<br/><br/><br>
		</p> -->
		<ul>
			<li>{{ $t('about-us.total-accounts') }}: <span>{{totalAccounts}}</span></li>
			<li>{{ $t('about-us.online-players') }}: <span>{{onlinePlayers}}</span></li>
			<li>{{ $t('about-us.highest-online') }}: <span>{{highestOnline}}</span></li>
		</ul>
	</section>
</template>

<script>
	import { mapGetters } from 'vuex';

	export default {
		name: "AboutUsSection",

		computed: {
			...mapGetters([
				'server/list',
				'monitoring/highestOnline',
				'monitoring/totalAccounts',
			]),

			servers() {
				return this['server/list'];
			},

			onlinePlayers() {
				return Object.values(this.servers).reduce((a, i) => (a + i.players.length), 0);
			},

			highestOnline() {
				return this['monitoring/highestOnline'];
			},

			totalAccounts() {
				return this['monitoring/totalAccounts'];
			}
		}
	}
</script>

<style scoped>
	#about-us {
		margin-top: -2rem;
	}

	#about-us .info {
		margin-left: 2rem;
		width: 60%;
		text-transform: uppercase;
		color: rgba(111, 131, 254, 0.5);
		font-size: 1.4rem;
	}

	#about-us ul {
		list-style: none;
		display: flex;
		justify-content: space-around;
		font-size: 1.4rem;
		margin-top: 5rem;
		color: rgba(111, 131, 254, 0.5);
	}

	#about-us ul li span {
		color: #ffb107;
	}
</style>
